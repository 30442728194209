import React, { Component } from 'react'
import { HashRouter, Route, Switch } from "react-router-dom";

import Home from './home/home';
import './App.css';

class App extends Component {

  constructor (props) {
    super(props)
    this.state = {
      showNav: false,
      infoClasses: 'info visuallyhidden hidden'
    }
  }
  
  render() {
    return (
      <HashRouter basename="/">
          <div className="App">

            <Switch>
              <Route path="*" render={(props) => <Home /> } />
            </Switch>

          </div>
      </HashRouter>
    );
  }
}

export default App;
