import React, { Component } from 'react'
import './home.css';

import beginners from '../assets/beginners-uc-13.gif';

class Home extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <>
        <img className="placeholder-image" src={beginners} alt="" />
        <p className="placeholder-text" style={{marginTop: 12}}>Beginners</p>
        <p className="placeholder-text">Animation Studio</p>
        <p className="placeholder-text"><a href="mailto:hello@beginners.work" target="_top">hello@beginners.work</a></p>
        <p className="placeholder-text">IG: <a href="https://www.instagram.com/beginners/"  target="_blank" rel="noopener noreferrer">@beginners</a></p>

        <p className="placeholder-text" style={{marginTop: 22}}>New site coming soon!</p>
      </>
    )
  }
}

export default Home
